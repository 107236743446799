import { useComparisonAspectRatio } from '~/composables/common'

const steps = [
  {
    step: 1,
    modal: { arrow: 'left' },
    offset: { x: 20, y: 200 },
    rect: { x: 200, y: 60 },
    title:
      '映像を比べる方法について説明します。\n「比べる」ボタンをタップし比べる動画を選択します。',
    url: '/'
  },
  {
    step: 2,
    modal: { arrow: 'top' },
    title:
      'お手本、マイリストから選択またはデバイス内の動画ファイルをアップロードします。',
    url: '/compare'
  },
  {
    step: 3,
    modal: { arrow: 'left', offsetX: 450, offsetY: -300 },
    title: '「比べる」では再生方法を選択できます。',
    body: [
      { title: '通常再生：', value: '撮影した映像で比べることができます。' },
      {
        title: '長さを合わせて再生：',
        value: '全体的な動きの違いを比べることができます。'
      },
      {
        title: 'ポイント合わせ再生：',
        value: '合わせたいタイミングを指定して比べることができます。'
      }
    ],
    url: '/compare'
  }
]
const highlightBorder = 10
const modalWidth = 392
let finishedStep = -1
export const currentStep = ref(-1)
export const currentStepData = ref()
export const isShowGuideModal = ref(false)
export const modalData = ref({
  step: 1,
  windowWidth: 0,
  windowHeight: 0,
  elementWidth: 0,
  elementHeight: 0,
  elementX: 0,
  elementY: 0,
  modalX: 0,
  modalY: 0,
  modalArrow: 'left',
  touchIconPosition: 'left'
})

const highlightElements = ref(
  new Map<string, HTMLElement | undefined | null>([['0', undefined]])
)
const modalElements = ref(
  new Map<string, HTMLElement | undefined | null>([['0', undefined]])
)
let highlightWatch: any
let stepWatch: any

const resetModalData = () => {
  currentStep.value = -1
  finishedStep = -1
  highlightElements.value.clear()
  currentStepData.value = undefined
  isShowGuideModal.value = false
  elementResizeObserver = null
  disconnectAllObservers()
}

const initModalData = async () => {
  resetModalData()
  highlightWatch = watch(
    highlightElements,
    (value) => {
      const step = currentStep.value
      if (value.get(`${step}`)) {
        if (finishedStep !== step) {
          showTutorialInstructions()
          finishedStep = step
        }
      }
    },
    { deep: true }
  )

  stepWatch = watch(currentStep, async (step) => {
    if (step !== 2) {
      await pageRedirect(step)
    }
    if (highlightElements.value.get(`${step}`) || step === 0) {
      if (finishedStep !== step) {
        showTutorialInstructions()
        finishedStep = step
      }
    }
  })

  await pageRedirect(0)
  currentStep.value = 0
}

const clearWatch = () => {
  highlightWatch()
  stepWatch()
}

const pageRedirect = async (step) => {
  if (steps[step]) {
    await navigateTo(steps[step].url)
  } else {
    await navigateTo('use')
  }
}

const showTutorialInstructions = () => {
  const step = currentStep.value
  listenWindowDOM()
  listenElementDOM(highlightElements.value.get(`${step}`), step)
  isShowGuideModal.value = true
  modalData.value.modalArrow = steps[step].modal.arrow
  currentStepData.value = steps[step]
}

const observers = new Set<ResizeObserver>()
let windowResizeObserver: ResizeObserver
let elementResizeObserver: ResizeObserver | null
const listenWindowDOM = () => {
  if (!windowResizeObserver) {
    windowResizeObserver = new ResizeObserver((entries) => {
      modalData.value.windowHeight = entries[0].borderBoxSize[0].blockSize
      modalData.value.windowWidth = entries[0].borderBoxSize[0].inlineSize
    })
    windowResizeObserver.observe(document.body)
    observers.add(windowResizeObserver)
  }
}

const listenElementDOM = (e: HTMLElement | undefined | null, step: number) => {
  if (!e) {
    setModalData(e, step)
    return
  }
  if (elementResizeObserver) {
    elementResizeObserver.disconnect()
  }
  if (step === 2) {
    windowResizeObserver.disconnect()
    windowResizeObserver = new ResizeObserver((entries) => {
      setModalData(e, step)
      modalData.value.windowHeight = entries[0].borderBoxSize[0].blockSize
      modalData.value.windowWidth = entries[0].borderBoxSize[0].inlineSize
    })
    windowResizeObserver.observe(document.body)
    setTimeout(() => {
      setModalData(e, step)
    }, 200)
  } else {
    elementResizeObserver = new ResizeObserver(() => {
      setModalData(e, step)
    })
    elementResizeObserver.observe(e)
  }

  observers.add(elementResizeObserver)
}

const setModalData = (e: HTMLElement | undefined | null, step: number) => {
  modalData.value.step = currentStep.value + 1
  if (e) {
    modalData.value.elementX = e.getBoundingClientRect().left - highlightBorder
    modalData.value.elementY = e.getBoundingClientRect().top - highlightBorder
    modalData.value.elementWidth =
      e.getBoundingClientRect().width + 2 * highlightBorder
    modalData.value.elementHeight =
      e.getBoundingClientRect().height + 2 * highlightBorder
    switch (step) {
      case 1: {
        if (useComparisonAspectRatio().value) {
          modalData.value.modalX =
            modalData.value.elementX +
            (modalData.value.elementWidth - modalWidth) / 2
          modalData.value.modalY =
            modalData.value.elementY + modalData.value.elementHeight / 2 - 20
        } else {
          let offsetX = 0
          if (
            modalData.value.elementWidth / 2 + 5 >
            modalData.value.elementHeight
          ) {
            offsetX =
              (modalData.value.elementWidth / 2 -
                modalData.value.elementHeight) /
              2
          }
          modalData.value.modalX =
            modalData.value.elementX +
            (modalData.value.elementWidth / 2 - modalWidth) / 2 +
            offsetX
          modalData.value.modalY =
            modalData.value.elementY + modalData.value.elementHeight / 2 + 175
        }
        modalData.value.modalArrow = 'top'
        modalData.value.touchIconPosition = 'right-bottom'
        break
      }
      case 2: {
        modalData.value.modalX = 700
        modalData.value.modalY = modalData.value.windowHeight - 500
        modalData.value.modalArrow = 'left'
        modalData.value.touchIconPosition = 'right-bottom'
        break
      }
    }
  } else {
    modalData.value.elementX = 20
    modalData.value.elementY = 200
    modalData.value.elementWidth = 280
    modalData.value.elementHeight = 60
    modalData.value.modalX =
      modalData.value.elementX + modalData.value.elementWidth + 28
    modalData.value.modalY = modalData.value.elementY - 70
    modalData.value.modalArrow = 'left'
    modalData.value.touchIconPosition = 'left'
  }
}

const disconnectAllObservers = () => {
  for (const observer of observers) {
    observer.disconnect()
    observers.delete(observer)
  }
}

// -----------------------------------------------------------
export const startTutorialInstructions = () => {
  initModalData()
}

export const nextTutorialInstructions = () => {
  if (currentStep.value + 1 >= steps.length) {
    terminateTutorialInstructions()
    return
  }
  currentStep.value++
}

export const terminateTutorialInstructions = () => {
  clearWatch()
  resetModalData()
  pageRedirect()
}

export const setGuideElement = (
  e: HTMLElement | undefined | null,
  tag: string
) => {
  if (isNaN(Number(tag))) {
    modalElements.value.set(tag, e)
  } else {
    highlightElements.value.set(tag, e)
  }
}

export const getHTMLElementFromRef = (ref: any): HTMLElement => {
  return ref.$el.parentNode.children[0]
}
